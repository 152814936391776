<template>
  <v-dialog v-model="showDialog" class="justify-center layout px-0" max-width="468px">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <mf-button label="Importar .MD" outlined color="primary" />
      </span>
    </template>
    <v-card style="overflow-x:hidden">
      <v-card-title>
        Selecione um arquivo .md
      </v-card-title>
      <v-card-text>
        <form enctype="multipart/form-data" class="ma-4">
          <v-file-input accept=".md" show-size small-chips outlined label="Termos .md" placeholder="Clique para selecionar" @change="onFileChange" />
        </form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <mf-button label="Cancelar" outlined color="error" @click="cancel" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    showDialog: false,
    markdownText: ''
  }),
  methods: {
    cancel() {
      this.showDialog = false
    },
    async onFileChange(file) {
      if (file) {
        this.markdownText = await file.text()
        this.$emit('markdown-converted', this.markdownText)
        this.showDialog = false
      } else {
        this.$emit('clear')
      }
    }
  }
}
</script>
